/**
 * Modules dependencies
 */
const restclient = require('nordic/restclient')({
  baseURL: 'https://api.mercadopago.com/v1',
  timeout: 60000,
  sendCSRFToken: false,
});

const Headers = require('../utils/Headers');

/**
 * Service interface
 */
class Service {
  /**
   * Get service base path
   */
  static getBasePath() {
    return '/card_tokens';
  }

  /**
   * Generate the card token
   * @param publicKey
   * @param data
   * @returns {*|Promise.<TResult>}
   */
  static generate(publicKey, data = {}, deviceType, testCredentials, checkoutVersion) {
    const headers = new Headers();

    // TODO: Tokenizer will be always logged false until we make autologin with payer-id
    // TODO: This if must be removed when Gateway fix the TEST environment and accept the header
    // When checkout product comes in here we need to add the productType
    if (!testCredentials) {
      headers.addTrackingProductId(deviceType, false, checkoutVersion);
    }

    return restclient
      .post(`${this.getBasePath()}?public_key=${publicKey}`, {
        data,
        headers: headers.get(),
      })
      .then(async (response) => Promise.resolve(response.data.id));
  }

  /**
   * Update the card token. Always send the fraudSessionId
   * @param publicKey
   * @param cardTokenId
   * @param fraudSessionId
   * @param data
   * @returns {*|Promise.<TResult>}
   */
  static update(publicKey, cardTokenId, fraudSessionId = '', data = {}, deviceType, testCredentials, checkoutVersion) {
    const headers = new Headers();

    // TODO: Tokenizer will be always logged false until we make autologin with payer-id
    // TODO: This if must be removed when Gateway fix the TEST environment and accept the header
    // When checkout product comes in here we need to add the productType
    if (!testCredentials) {
      headers.addTrackingProductId(deviceType, false, checkoutVersion);
    }

    if (fraudSessionId) {
      // Append the session_id for Fraud
      data.device = {
        meli: {
          session_id: fraudSessionId,
        },
      };
    }

    return restclient
      .put(`${this.getBasePath()}/${cardTokenId}?public_key=${publicKey}`, {
        data,
        headers: headers.get(),
      })
      .then(async (response) => Promise.resolve(response.data.id));
  }

  /**
   * Generate the card token
   * @param publicKey
   * @param data
   * @returns {*|Promise.<TResult>}
   */
  static validateEsc(publicKey, data = {}, deviceType, testCredentials, checkoutVersion) {
    const headers = new Headers();

    if (!testCredentials) {
      headers.addTrackingProductId(deviceType, false, checkoutVersion);
    }

    return restclient
      .post(`${this.getBasePath()}?public_key=${publicKey}`, {
        data,
        headers: headers.get(),
      })
      .then(async (response) => Promise.resolve(response.data));
  }
}

/**
 * Expose Service
 */
module.exports = Service;
