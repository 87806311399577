/**
 * Method that insert a new param or update an existing one in the param url
 * @param {String} key
 * @param {String} value
 * @param {Number} expDays
 */
const setCookie = (key, value, expDays = 365) => {
  const path = '/';
  const secure = 'secure'; // allways secure
  const samesite = 'SameSite=strict'; // allways strict
  const date = new Date();

  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000); // 99 days

  const expires = `expires=${date.toUTCString()}`;
  const cookie = `${key}=${value};${expires};${secure};${samesite};${path}`;

  document.cookie = cookie;
};

const getCookie = (key) => {
  const expectedCookieNameRegExp = "/([.$?*|{}()[]\\/+^])/g, '\\$1'";
  const matches = new RegExp(`(?:^|; )${key.replace(expectedCookieNameRegExp)}=([^;]*)`).exec(document?.cookie);

  return matches ? decodeURIComponent(matches[1]) : false;
};

const deleteCookiesByKey = (key) => setCookie(key, '', -1);

module.exports = {
  setCookie,
  getCookie,
  deleteCookiesByKey,
};
