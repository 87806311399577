/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const { COOKIES,
        ERROR_SPA: { ACTIONS_ESC_VALIDATOR },
        GATEWAY_ERROR_MAP: { INVALID_ESC } } = require('../../../constants/app');
const cardTokensService = require('../../service/cardTokens');
const { setCookie, deleteCookiesByKey } = require('../../utils/CookieUtils');
const {
  ESC_VALIDATE,
  ESC_CARD_TOKEN,
  ESC_RESET,
  REQUEST_STARTED,
  REQUEST_FINISHED,
} = require('./types');
const { logErrorFromClient } = require('../../utils/logTags');
const { trackInfo } = require('../../service/api');

const escValidate = createAction(ESC_VALIDATE);
const escReset = createAction(ESC_RESET);
const requestStarted = createAction(REQUEST_STARTED);
const requestFinished = createAction(REQUEST_FINISHED);
/**
 * Export functions
 */
module.exports = {
  [ESC_CARD_TOKEN]: () =>(dispatch, getState)=>{
    const currentState = getState();
    dispatch(escValidate(currentState?.esc));
    return currentState.esc?.cardTokenId
  },
  [ESC_VALIDATE]: (cardId, esc) => (dispatch, getState) => {
    const currentState = getState();
    const {configurations, page} = currentState;
    const keyEsc = `${COOKIES.ESC}${cardId}`;

    if (!esc) {
      dispatch(escReset({}));
      return ;
    }

    const data = {
      card_id: cardId,
      require_esc: true,
      device:{
        fingerprint: {
          vendor_ids: [
            {
              name: "browser",
              value: page?.data.internalDeviceId
            }
          ]
        }
      },
      esc
    };

    dispatch(requestStarted({ requestId: cardId }));

    cardTokensService
      .validateEsc(configurations?.publicKey, data, configurations?.deviceType, configurations?.testCredentials, configurations?.version)
      .then((response) => {
        setCookie(keyEsc, response.esc);
        dispatch(escValidate({cardId, cardTokenId: response.id}));
        dispatch(requestFinished({ requestId: cardId, leaveLoading: false }));
        return Promise.resolve(response);
      })
      .catch((error) => {
        const isEscInvalid = error?.response?.data?.cause[0]?.code;

        deleteCookiesByKey(keyEsc);
        dispatch(requestFinished({ requestId: cardId, leaveLoading: false }));
        dispatch(escValidate({ cardId, cardTokenId: undefined}));

        if (isEscInvalid === INVALID_ESC) {
          trackInfo({ message: "cardToken: Invalid ESC" });
        } else {
          logErrorFromClient(error, ACTIONS_ESC_VALIDATOR,'[cardTokenEscPromise][ESC_VALIDATE]');
          return Promise.reject(error);
        }
      });
  },
  [ESC_RESET]: () => (dispatch) => dispatch(escReset({})),
};
