const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const EscActions = require('../../spa/actions/esc');
const installmentActions = require('../../spa/actions/installment');
const inputActions = require('../../spa/actions/inputValues');
const { ESC_RESET, INSTALLMENT_CLEAN, CLEAN_INPUT_VALUES } = require('../../spa/actions/types');
const Optimus = require('../../containers/Optimus');
const Page = require('../../components/Page');
const {
  CURRENT_STEP: { PAYMENT_OPTION_FORM },
} = require('../../../constants/app');

const GenericPage = (props) => {
  if (props.currentStep === PAYMENT_OPTION_FORM) {
    props.escActions[ESC_RESET]();
    props.installmentActions[INSTALLMENT_CLEAN]();
    props.inputActions[CLEAN_INPUT_VALUES]();
  }

  return (
    <Page
      title={props.stepTitle}
      currentStep={props.currentStep}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <Optimus
        components={props.components}
        history={props.history}
        siteId={props.siteId}
        urls={props.urls}
        publicKey={props.publicKey}
        deviceType={props.deviceType}
        captcha={props.captcha}
        hiddenComponents={props.hidden_components}
      />
    </Page>
  );
};

GenericPage.defaultProps = {
  stepTitle: '',
  currentStep: '',
  urls: {
    tyc: {
      link: '',
    },
  },
  trackingPath: '',
  analytics: {},
  deviceType: '',
  components: [],
  history: null,
  siteId: '',
  publicKey: '',
};

GenericPage.propTypes = {
  stepTitle: PropTypes.string,
  currentStep: PropTypes.string,
  urls: PropTypes.shape({
    tyc: PropTypes.shape({
      link: PropTypes.string,
    }),
  }),
  trackingPath: PropTypes.string,
  analytics: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  deviceType: PropTypes.string,
  components: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object, // eslint-disable-line
  siteId: PropTypes.string,
  publicKey: PropTypes.string,
};

module.exports = GenericPage;
const mapDispatchToProps = (dispatch) => ({
  escActions: bindActionCreators(EscActions, dispatch),
  installmentActions: bindActionCreators(installmentActions, dispatch),
  inputActions: bindActionCreators(inputActions, dispatch),
});

module.exports = connect(null, mapDispatchToProps)(GenericPage);
